import { createStore } from 'vuex';
import * as _ from 'lodash';

const s3 = process.env.VUE_APP_S3;
// Create a new store instance.
const store = createStore({
  state () {
    return {
      booking: null,
      query: {},
      services: [],
      allServices: [],
      agentName: null,
      agentDescription: null,
      agentEmail: null,
      agentPhone: null,
      agentMeet: null,
      hotels: [],
      sellerImg: null,
      sellerImgBig: null,
      coverImg: null,
      tripName: null,
      paxName: null,
      loaded: false,
      lang: 'en',
      languageNotes: [],
      prices: [],
      hidePrices: false,
      coverService: null,
      coverNote: null

    }
  },
  mutations: {
    setBooking (state, values) {
      if (values) {
        state.allServices = Object.assign([], _.filter(values, (r) => {
          if (r.ServiceTypeCode == 'IN') return false;
          if (r.ServiceTypeCode == 'AC') return false;
          if (r.ServiceTypeCode == 'XF') return false;
          if (r.ServiceTypeCode == 'FT') return false;
          return true;
        }));
        state.hotels = Object.assign([], _.filter(values, (r) => {
          if (r.ServiceTypeCode == 'AC') return true;
          return false;
        }))
        let cover = _.find(values, (r) => r.ServiceTypeCode == 'IN');
        if (cover) {
          state.coverImg = `${s3}/OPT_ID.${cover.OPT_ID}.0.html`;
        }
        state.coverService = values.find(r => r.ServiceTypeCode == 'IN');

      }
    },
    setQuery (state, value) {
      state.query = Object.assign({}, value);
    },
    setSeller (state, seller) {
      state.sellerImg = seller.ProfileImageUrl;
      state.sellerImgBig = seller.ProfileImageUrlBig;
      if (seller.UserProfiles) {
        let profile = _.find(seller.UserProfiles, (r) => r.Language.Code == state.lang);
        if (profile) {
          state.agentDescription = profile.Description;
        }

      }
      state.agentPhone = seller.Phone;
      state.agentName = `${seller.FirstName} - ${seller.LastName}`;
      state.agentMeet = seller.CalendarLink;
      state.agentEmail = seller.Email;
    },
    setNames (state, values) {
      values.forEach(row => {
        if (row.CATEGORY == 'PAX') state.paxName = row.MESSAGE_TEXT;
        if (row.CATEGORY == 'VIA') state.tripName = row.MESSAGE_TEXT;
      });
    },
    setPrices (state, values) {
      state.prices = values;
    },
    setLanguageNotes (state, values) {
      var services = state.allServices || [];
      var languageNotes = values || [];
      state.languageNotes = languageNotes;
      if (languageNotes) {
        services = services.map(service => {
          let updatedService = { ...service }; // Create a copy of the service object
          
          const matchingNote = languageNotes.find(note => note.OPT_ID === service.OPT_ID && note.NTS_CATEGORY === 'ODE' && note.day == service.day);
          if (matchingNote) {
            updatedService.Titulo = matchingNote.MESSAGE_TEXT;
            updatedService.pcmPkgName = matchingNote.pcmPkgName;
            updatedService.LineType = matchingNote.LineType;
            updatedService.NTS_CATEGORY = matchingNote.NTS_CATEGORY;
          }
          
          const matchingNote2 = languageNotes.find(note => note.OPT_ID === service.OPT_ID && note.NTS_CATEGORY === 'OIE' && note.day == service.day);
          if (matchingNote2) {
            updatedService.Descripcion = matchingNote2.MESSAGE_TEXT;
            updatedService.pcmPkgName = matchingNote2.pcmPkgName;
            updatedService.LineType = matchingNote2.LineType;
            updatedService.NTS_CATEGORY = matchingNote2.NTS_CATEGORY;
          }
          
          if (updatedService.LineType == 'Package Component') {
            const noteHeaderODE = languageNotes.find(note => note.pcmPkgName === updatedService.pcmPkgName && note.LineType === 'Package Header' && note.day == service.day && note.NTS_CATEGORY === 'ODE');
            const noteHeaderOIE = languageNotes.find(note => note.pcmPkgName === updatedService.pcmPkgName && note.LineType === 'Package Header' && note.day == service.day && note.NTS_CATEGORY === 'OIE');
            
            if (noteHeaderODE && noteHeaderODE.MESSAGE_TEXT && noteHeaderODE.MESSAGE_TEXT.length > 0) {
              updatedService.Titulo = '';
            }
            if (noteHeaderOIE && noteHeaderOIE.MESSAGE_TEXT && noteHeaderOIE.MESSAGE_TEXT.length > 0) {
              updatedService.Descripcion = '';
            }
          }
          
          return updatedService;
        });
      }
      state.services = services;
      console.log(state.coverService.OPT_ID);
      state.coverNote = values.find(r => r.OPT_ID == state.coverService.OPT_ID);
    },
    setLang (state, lang) {
      state.lang = lang;
    },
    setHidePrices (state, hidePrices) {
      state.hidePrices = hidePrices;
    }
  } 
})

export default store;
