<template>
  <div id="say-trip">
    <div class="relative">
      <div class="card-sticky bg-dark p-3 md:p-4 rounded-tl-xl rounded-bl-xl w-64 md:w-96">
        <div class="flex gap-4">
          <div class="w-4/12 md:w-5/12">
            <img :src="sellerImg" class="rounded-full  border-2 border-primary">
          </div>
          <div class="w-8/12 md:w-6/12  text-white">
            <p class="font-bold text-sm sm:text-lg">{{ agentName }}</p>
            <p class="text-xs md:text-sm uppercase">{{ tr('Travel Specialist') }}</p>
            <div class="hidden md:block">
              <p class="text-sm"><img src="@/assets/trip/img/wpp.svg" class="inline-block mr-2"><span v-html="agentPhone"></span></p>
              <p class=" text-sm "><a :href="agentMeet" target="_blank"><img src="@/assets/trip/img/meet.svg" class="inline-block mr-2">{{ tr('Reserve a meet') }}</a></p>
            </div>
            <a data-modal-target="default-modal" data-modal-toggle="default-modal" @click="showModal = true"
              class="mt-1 md:mt-3 cursor-pointer underline md:no-underline inline-block  md:px-5 py-1 text-sm md:border border-white rounded-sm md:hover:bg-white md:hover:text-dark transition-all">
              {{ tr('More about me') }}</a>

          </div>
        </div>
      </div>
    </div>
    <main v-if="ready">
      <Header></Header>
      <SectionWhy></SectionWhy>
      <Overview></Overview>
      <DayList></DayList>
      <Pricing v-if="!hidePrices"></Pricing>
      <NextSteps></NextSteps>
      <CarbonNeutral></CarbonNeutral>
      <FAQs></FAQs>
      <Footer></Footer>

      <!-- Main modal -->
      <div id="default-modal" tabindex="-1" 
           :class="{ 'hidden': !showModal, 'flex': showModal }"
           class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
           @keydown.esc="closeModal"
           @click.self="closeModal">
        <div class="relative p-4 w-full max-w-2xl max-h-full">
          <!-- Modal content -->
          <div class="relative bg-white rounded-xl shadow-lg">
            <!-- Modal header -->
            <div class="flex items-center justify-between rounded-t-xl h-64 overflow-hidden">
              <img :src="sellerImgBig" class="object-cover object-center w-full h-full" alt="Seller image">
              <button type="button"
                      class="text-white text-sm w-8 h-8 ms-auto inline-flex absolute top-3 right-3 justify-center items-center"
                      @click="closeModal"
                      aria-label="Close modal">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                      viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
              </button>
            </div>
            <!-- Modal body -->
            <div class="p-5 md:p-10 mb:pb-12 space-y-4">
              <div class="text-center mb-2">
                <svg class="mx-auto" width="23" height="14" viewBox="0 0 23 14" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.608918 8.04466L10.9311 13.8498C11.289 14.0501 11.7217 14.0501 12.0795 13.8498L22.3911 8.04466C23.203 7.58728 23.203 6.4046 22.3911 5.94723L12.0769 0.150203C11.7217 -0.0500677 11.289 -0.0500677 10.9311 0.150203L0.608918 5.94723C-0.202973 6.4046 -0.202973 7.58728 0.608918 8.04466Z"
                        fill="#F19F00" />
                </svg>

                <h6 class="text-dark text-xl font-secondary mb-1 mt-3">{{ agentName }}</h6>
                <a class="text-primary text-sm">{{agentEmail}}</a>
              </div>
              <p class="text-sm text-dark opacity-70" v-html="agentDescription">
              </p>
            </div>

          </div>
        </div>
      </div>
      <div v-if="showModal" class="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40"></div>

    </main>
    <Transition>
        <BlockUI v-if="!ready"></BlockUI>
    </Transition>

  </div>
</template>

<script>
import Header from '@/components/itinerary/Header.vue';
import SectionWhy from '@/components/itinerary/SectionWhy.vue';
import Overview from '@/components/itinerary/Overview.vue';
import DayList from '@/components/itinerary/DayList.vue';
import Pricing from '@/components/itinerary/Pricing.vue';
import NextSteps from '@/components/itinerary/NextSteps.vue';
import CarbonNeutral from '@/components/itinerary/CarbonNeutral.vue';
import FAQs from '@/components/itinerary/FAQs.vue';
import BlockUI from '@/components/tools/BlockUI.vue';
import Footer from '@/components/tools/Footer.vue';
import '@/assets/css/main.scss';
const api = window.api;
//const s3 = process.env.VUE_APP_S3;
export default {
  name: 'SayTrip',
  data() {
    return {
      showModal: false,
      loading: true,
      loadingNotes: true,
      hidePrices: false
    }
  },
  computed: {
    agentName() {
      return this.$store.state.agentName;
    },
    agentMeet() {
      return this.$store.state.agentMeet;
    },
    agentPhone() {
      return this.$store.state.agentPhone;
    },
    agentEmail() {
      return this.$store.state.agentEmail;
    },
    agentDescription() {
      return this.$store.state.agentDescription;
    },
    sellerImg () {
      return this.$store.state.sellerImg;
    },
    sellerImgBig () {
      return this.$store.state.sellerImgBig;
    },
    ready () {
      return !this.loading && !this.loadingNotes;
    }
  },
  async mounted() {
    api.get('/api/tpsql/trip_cover', this.$route.query, null, (result) => {
      this.$store.commit('setNames', result);
    });
    api.get('/api/tpsql/trip_seller_db', this.$route.query, null, (r) => {
        if (r) {
          this.$store.commit('setSeller', r);
        }
    });
    /*api.get('/api/tpsql/trip_seller', this.$route.query, null, (r) => {
      if (r && r[0]) {
        this.$store.commit('setSeller', r[0]);
        let email = r[0].Email;
        api.get('/api/tpsql/trip_seller_db', {email: email}, null, (r) => {
        if (r) {
          //this.$store.commit('setSeller', r);
          console.log(r);
        }
      });

      }
    });*/

    let res = await api.get('/api/tpsql/booking', this.$route.query);
    if (res) {
      this.$store.commit('setBooking', res);
      this.$store.commit('setQuery', this.$route.query);
      let hidePrices = res && res[0] && res[0].HidePrices;
      if (hidePrices) hidePrices = hidePrices.replace(/ /g, '');
      if (hidePrices) {
        this.hidePrices = hidePrices;
        this.$store.commit('setHidePrices', this.hidePrices);
      } else {
        api.get('/api/tpsql/trip_prices', this.$route.query, null, (result) => {
          this.$store.commit('setPrices', result);
        });
      }
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
    api.get('/api/tpsql/language_notes', this.$route.query, null, (r) => {
      this.$store.commit('setLanguageNotes', r);
      setTimeout(() => {
        this.loadingNotes = false;
      }, 1000);
    });
  },
  components: {
    Header,
    SectionWhy,
    Overview,
    DayList,
    Pricing,
    NextSteps,
    CarbonNeutral,
    FAQs,
    BlockUI,
    Footer
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },

    handleEscKey(event) {
      if (event.key === 'Escape' && this.showModal) {
        this.closeModal();
      }
    },
    tr(key) {
      return this.$root.tr(key);
    }
  },
  watch: {
    showModal(newValue) {
      if (newValue) {
        // Add event listener when modal is opened
        document.addEventListener('keydown', this.handleEscKey);
      } else {
        // Remove event listener when modal is closed
        document.removeEventListener('keydown', this.handleEscKey);
      }
    }
  },

  beforeUnmount() {
    // Clean up event listener when component is destroyed
    document.removeEventListener('keydown', this.handleEscKey);
  }
}
</script>

<style scoped>
.trip-body {
  overflow: scroll;
}

.v-leave-active {
  transition: opacity 1s ease;
}


.v-leave-to {
  opacity: 0;
}
</style>
